import React from "react";
import { graphql } from "gatsby";
import Grid from "../components/Grid";

const NotFoundPage = ({ data }) => {
    const siteTitle = data.site.siteMetadata.title;

    return (
        <>
            <Grid>404 Not Found</Grid>
            <p>{siteTitle}</p>
        </>
    );
};

export default NotFoundPage;

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`;
